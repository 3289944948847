@tailwind base;
@tailwind components;
@tailwind utilities;




@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');


/*--------------------------------------------------------------
# VARIABLES CSS
--------------------------------------------------------------*/


:root{


 --white:white;
 --light-green:#2BE09F;
 --dark-green:#2FBE89;
 --green-unlock1:#51FFBD;
 --green-unlock2:#65FF51;
 --dark-blue:#2F3B59;
 --tropaz-blue:#234D93;
 --dark-shade-blue:#24324F;
 --hover-dark-blue:#2e487b;
 --Whitesmoke:#F5F5F5;
 --grey:#fbfbfb;
 --mid-grey:#B5B5B5;
 --dim-grey:#717171;
 --whisper-grey:#E4E4E4;
 --grey-85:#d9d9d9;
 --dark-grey:#707070;
 --red:#E51F35;
 --red-hover:#df3a45;
 --font-family-principal:'Montserrat', sans-serif;
 --bg-modalLang-1:rgba(26 ,27, 49,1);
 --bg-modalLang-2:rgba(13 ,15 ,34,1);


}

/*--------------------------------------------------------------
# General Style + classes
--------------------------------------------------------------*/


body{
 font-family: var(--font-family-principal);
 font-weight: 400;
 overflow-x: hidden;


}


/* Scrool */

 ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: var(--dark-green);
}
::selection {
  background-color: var(--dark-green);
  color: #fff;
}




ul {list-style-type: none;}


.width-padding{
 @apply  xl:px-28 xs:px-5  px-3 ;

}


.border-style{


  border:1px solid #707070;
  border-radius:10px;
 }
 
 .btn-principal{
   @apply bg-lightGreen text-center uppercase cursor-pointer hover:bg-darkGreen;
  
  
  }


 .bg-langSwitch-1{
background: var(--bg-modalLang-1);
 }
 .bg-langSwitch-2{
  background: var(--bg-modalLang-2);
 }

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/


.title-footer{
  @apply font-semibold  sm:text-xl text-lg  pb-5 mb-6 mt-8;
 border-bottom: 1px solid var(--dim-grey);
}




.infos-contact{
 @apply flex mb-2 items-center;
}




@media (max-width:639px){
 .hide-sm{
   display: none;
 }
}



@media (max-width:1023px) and (min-width:768px){
 .btn-footer, .input-footer{
   width: 55%;
 }


}


@media (max-width:1023px) and (min-width:330px){
 footer p{
   @apply w-4/5;
 }
}


/*--------------------------------------------------------------
# Header 
--------------------------------------------------------------*/


/* Burger effect */

.burger-icon .w-9 {
  
    transition: transform 0.3s ease-out;
  
}

.close .burger-icon .w-9:first-child {
  
  transform: rotate(45deg) translate(6px, 4px);

    
}
    

 

.close .burger-icon .w-9:last-child {
  transform: rotate(-45deg) translate(10px, -8px);
   
 
}


.close .burger-icon .w-9:nth-child(2) {
  opacity: 0;
 
}


/* Burger menu max-width:290px fix to be responsive */

@media (max-width:290px){

  header>div{
    flex-direction: column;
    gap: 1rem;
  }
}

/* Language switch*/
.multiLanguage{
  margin-right: 17px;
}

.arrow-down {
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg) translateY(-20%);
  -webkit-transform: rotate(45deg) translateY(-20%);
  right: -17px;
  top: 20%;
  cursor: pointer;
}
  

@media (max-width:1023px){
  .desktop-menu{
    display: none;
  }
}



/*--------------------------------------------------------------
# Capsule details component
--------------------------------------------------------------*/

.detail-item img{
height: 27px;
}

.detail-title{
  @apply text-darkBlue font-semibold	text-xl mb-5;
}

.detail-item{
  @apply flex items-center gap-x-3 mb-5;
}

.detail-item img{
  height: 20px;
  width: 20px;
}

.detail-item p{
  @apply text-darkGreey text-xs sm:text-base	;
  max-width: 80%;
}


.item-info{
  @apply flex flex-col items-center sm:flex-row sm:flex-wrap ;
}

.item-info img{
  margin: auto;
}

.item-info h3{
  @apply text-xs font-semibold	sm:pl-2;
}


@media (max-width:1024px){

 .reservationBox{
  margin-top: 1.25rem !important;
 }
 .more-details{
  margin-top: 0
 }

 
}


.content-Apropos .item{
  @apply flex justify-between py-3 flex-wrap gap-x-3;
  border-bottom: 1px solid var(--dark-grey);
}

.content-Apropos .item:last-child{
  border:none;
}
.content-Apropos .item h3{
  @apply text-lightGreen font-semibold	uppercase;
}

.content-Apropos .item p{
  @apply text-dimGreey text-sm	 ;
}


/* Reservation Box */

.reservationBox .content-box , .shadow-boxed{
  box-shadow: 0px 3px 6px #00000029;
}



.border-style1{
  border: 1px solid var(--grey-85);
  border-radius: 0.3rem;
}

.border-style2{
  @apply rounded-2xl p-4;
  border:1px solid var(--grey-85);
}

.border-style3{
  @apply px-3 py-2 border-midGreey rounded-md	 border;
}



/* Map */

.leaflet-container{

  height:450px;
  width:100%

} 

.leaflet-pane, .leaflet-control,.leaflet-bottom,.leaflet-top {

  z-index: 0 !important;
}
/* Modal fixed */

.fixedMenu{
 position: static;
}

@media (max-width:1023px){
  .fixedMenu{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    min-height: 60vh;
  }

  .popUpContainer{
    display: none;
  }
}

/*--------------------------------------------------------------
# PopUp component
--------------------------------------------------------------*/


@media (min-width:1024px){

  }
/* Close */

.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 32px;
  height: 32px;
  opacity: 0.6;
  cursor: pointer;
}
.closeBtn:hover {
  opacity: 1;
}
.closeBtn:before, .closeBtn:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.closeBtn:before {
  transform: rotate(45deg);
}
.closeBtn:after {
  transform: rotate(-45deg);
}

.toast{
  z-index: 9999;
}
.toastSuccess{

  @apply text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200;
}

.toastSucessColor{
  @apply text-lightGreen;
}

.toastErrorColor{
  @apply text-redDanger;
}

.toastError{
  @apply text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200;
}


/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/

/* Slide in top */


.toast.slide-in-top{
  -webkit-animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}
@media  (min-width:1024px){
  
  @media  (min-width:1024px){
    .slide-in-top {
     -webkit-animation: slide-in-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
             animation: slide-in-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
         
   }
  
  
   .slide-in-top2 {
     -webkit-animation: slide-in-top2 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
             animation: slide-in-top2 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
         
   }
  
  
   
  }
  @-webkit-keyframes slide-in-top2 {
   0% {
     -webkit-transform: translateY(50%) translateX(-50%);
             transform: translateY(50%) translateX(-50%);
     opacity: 0;
     filter: blur(15px) brightness(0);
     -webkit-filter: blur(15px) brightness(0);
   }
   100% {
     -webkit-transform: translateY(-50%) translateX(-50%);
             transform: translateY(-50%) translateX(-50%);
     opacity: 1;
     -webkit-filter: blur(0) brightness(1);
     filter: blur(0) brightness(1);
   }
  }
  @keyframes slide-in-top2 {
   0% {
     -webkit-transform: translateY(50%) translateX(-50%);
             transform: translateY(50%) translateX(-50%);
     opacity: 0;
     filter: blur(15px) brightness(0);
     -webkit-filter: blur(15px) brightness(0);
   }
   100% {
     -webkit-transform: translateY(-50%) translateX(-50%);
             transform: translateY(-50%) translateX(-50%);
     opacity: 1;
     -webkit-filter: blur(0) brightness(1);
     filter: blur(0) brightness(1);
   }
  }
  
  
  @-webkit-keyframes slide-in-top {
   0% {
     -webkit-transform: translateY(150%) translateX(-50%);
             transform: translateY(150%) translateX(-50%);
     opacity: 0;
   }
   100% {
     -webkit-transform: translateY(50%) translateX(-50%);
             transform: translateY(50%) translateX(-50%);
     opacity: 1;
    }
  }
  @keyframes slide-in-top {
   0% {
     -webkit-transform: translateY(150%) translateX(-50%);
             transform: translateY(150%) translateX(-50%);
     opacity: 0;
   
   }
   100% {
     -webkit-transform: translateY(50%) translateX(-50%);
             transform: translateY(50%) translateX(-50%);
     opacity: 1;
    
   }
  }
  
}

/* Modal auth */

#authentication-modal .bg-whisperGreey{
  display: none;
}



#authentication-modal .container-modal  {
  transform: translate(-50%,-50%);
}

#authentication-modal .btn-principal{
  width: 100%;
}

#authentication-modal .btns-account{
  display: flex;
}

#authentication-modal  .form-account{
  max-width: 100%;
  transition: 0.3s ease-in-out;
}

.form-account input:focus{
  outline: none;
}
.form-account input:hover{
  border-color: var(--dark-green);
}

@media (max-width:400px){
  #authentication-modal .gap-10{
    gap: 0.5rem;
  }
}

.form-account{
  @apply bg-white rounded sm:p-4 mt-8 sm:shadow-boxShadow;
 
 
}

#authentication-modal  .form-account{
  box-shadow: none;
}



@media (min-width:1023px){
  .slide-in-fwd-top {
    -webkit-animation: slide-in-fwd-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-in-fwd-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  @-webkit-keyframes slide-in-fwd-top {
    0% {
      -webkit-transform: translateZ(-1400px) translateY(-5px);
              transform: translateZ(-1400px) translateY(-5px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-fwd-top {
    0% {
      -webkit-transform: translateZ(-1400px) translateY(-5px);
              transform: translateZ(-1400px) translateY(-5px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  
}

@media(max-width:400px){
  #authentication-modal .label-sub {
    font-size: 0.7rem;
  }
  #authentication-modal .input-checkbox{
    min-width: 50px;
  }

  #authentication-modal .w-11\/12 {
    width: 100%;
}
}

/* Delete account hide in modal */

#authentication-modal .removeAccount{
  display: none;
}

#authentication-modal .modalRemove{
  display: none;
}




/*--------------------------------------------------------------
# Swiper
--------------------------------------------------------------*/

/* date PIcker */
.react-datepicker-popper {
  z-index: 3 !important;
}

.react-datepicker__header{
  background: var(--dark-blue) !important;
  color: var(--white) !important;
}

.react-datepicker__day-name, .react-datepicker__current-month{
  color: var(--white) !important;
}

.react-datepicker__day--selected{
  background-color: var(--light-green) !important;
}


.swiper-button-next, .swiper-button-prev{
  color: var(--dark-blue) !important;
 
}
.swiper-button-next{
  right: -5px !important;
}

.swiper-button-prev{
  left: -5px !important;
}


.swiper-button-next:after, .swiper-button-prev:after {
font-size: 1.5rem !important;
}
 

/* 3 step css */

.btn-bottom-absolute button{
  position: absolute;
  bottom: 15px;
}


/* Responsive video */

.responsive-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1024px) {
  .responsive-video {
    max-width: 550px;

  }
}

.cursor-not{
  cursor: none !important; 
  pointer-events: none;
}

/* Effects */

.reverse{
  transition: all .3s ease;
  display: inline-block;
  
  transform: rotate(180deg);
}
.reverse:hover {
  background: var(--dark-blue);
  color:var(--light-green);
  transform: rotate(360deg);
}

/* Shadow box */

.shadow-box{
  box-shadow: 0px 3px 6px #00000029;
}

/* Animation scale in center */

.scale-in-center {
	-webkit-animation: scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

/* Hover effects */

.dropDownItems a, footer a{
  transition: all 200ms linear;
}

.transition-500ms{
  transition: all 200ms linear;
}
 .dropDownItems a:hover , footer a:hover{
  color: var(--light-green);
}

a.assistance:hover{
  color: white ;
}
/* Vibrate */

.vibrate-1:hover {
	-webkit-animation: vibrate-1 0.3s linear infinite both;
	        animation: vibrate-1 0.3s linear infinite both;
}


 @-webkit-keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }
  @keyframes vibrate-1 {
    0% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
    20% {
      -webkit-transform: translate(-2px, 2px);
              transform: translate(-2px, 2px);
    }
    40% {
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px);
    }
    60% {
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }
    80% {
      -webkit-transform: translate(2px, -2px);
              transform: translate(2px, -2px);
    }
    100% {
      -webkit-transform: translate(0);
              transform: translate(0);
    }
  }


  /* Rotate 360deg */
  .rotate-360deg:hover{
    animation: rotate  0.3s ease-in;
  }


  @keyframes rotate {
    0%{
      transform: rotate(0);
    }
    100%{
      transform: rotate(360deg);
    }
  }

  

  /* Anuller reservation add border right in max-width:500px */

  /* @media (max-width:500px){
    .anulled{
      @apply pr-5 border-r-2 border-r-darkGreey;
    }
  } */

  /* border dark blue */

  .border-darkBlue{
    border: 1px solid var(--dark-blue);
   
  }

  /* Date picker input */

  .date-picker-input:focus , .date-picker-input:focus-visible, .react-datepicker-wrapper:focus-visible{

    border: none !important;
    outline: none;
  }


  /* react tel numbers */

  .react-tel-input .form-control  {
   @apply bg-gray-50 border  text-gray-900 text-sm rounded-lg 
   focus:border-darkGreen block w-full py-2.5 h-auto 	 !important;
  }
  


  .swiper-div-container {
    height: 100%;
    overflow-x: hidden;
    
  }
  
  .swiper-div-container.hideIt {
    height: 0;
  }

#cgu p{
  color: #555;
  line-height: 1.6;
  font-size: 0.9rem;
  margin: 0.3rem 0.2rem;
}

#cgu span{
  margin: 0 0.2rem;
}
#cgu ul{
  color: #555;
}
#cgu ul, #faq ul{
  @apply list-disc px-6 text-sm mb-1;
} 

#cgu li , #faq ul{
  @apply mb-1;
}

#faq span, #faq a{
  @apply font-bold text-darkBlue;
}


  /* MentionsLegals page */

  .mention-link{
    @apply font-semibold text-darkBlue;
  }

  .mention-title{
    @apply font-bold text-darkBlue text-lg mb-5 relative inline-block;
  }

  .mention-title::before{
    @apply bg-lightGreen absolute -bottom-1 w-1/2	h-0.5;
    content:"";
  }

  #mentions-legals article{
    @apply mb-4;
  }

  .mention-paragraph{
    @apply py-0.5 ;
    color: #555;
    
  }

  /* Login admin */
  
  .border-bottom-error{
    border-bottom: 1px solid #f5f5f5;
  }


  /* Fade in */

  .fade-in {
    -webkit-animation: fade-in 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation: fade-in 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  @-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
.login-shadow{
  -webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
}

/* Admin links */

.admin-links a.active{
  color: var(--light-green);
}


/* add calender icon to DatePicker*/

.add-calendar-icon {
  background: url('../src/assets/images/calendar.svg') no-repeat right;
  background-size: 30px;
}


/* LANG SWITCH */


.item-lang{
  @apply xxs:w-1/2 w-full py-5 px-6 flex justify-start items-center;
  border-bottom: 0.0625rem solid #000;
  }
 
 
 .ml-lang{
  border-left: 0.0625rem solid #000;
 }
 

 /* Modal max-h responsive */

 @media (max-height:900px){
  .modalResHeight{
  @apply max-h-95vh overflow-y-auto;
 }


 }

@media (max-height:653px){
  .reserve-popup{
    transform: translateX(-50%);
  }
}

@media (max-height:500px){
  .reserve-popup{
    top: 50px;
  }
}

.boxShadowCookie{
  box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px;
 
 
 }
 

 .boxShadowCookie:hover{
  transition-duration: 1s;
  box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
 }